import * as React from "react";
import HeadSeo from "../../components/HeadSeo.js";
import Layout from "../../components/Layout.js";
import DynamicSidebar from "../../components/DynamicSidebar";
import BlogLists from "../../components/BlogLists.js";

export const Head = ({ pageContext }) => {
  if (!pageContext) return null;

  const { index, group } = pageContext;
  if (!group) return null;

  const randomImage = group[1]?.img ?? null;

  return (
    <HeadSeo
      title={`Our Blogs Pages #${index}`}
      description={`Page ${index} of our blog listing pages, where we specialize in covering all aspects from across the globe.`}
      seoImg={randomImage}
    ></HeadSeo>
  );
};

const BlogList = ({ pageContext }) => {
  const { group, index, first, last } = pageContext;

  const prevID = index - 1 === 1 ? "1" : (index - 1).toString();
  const prevUrl = `/blog/${prevID}/`;

  const nextID = (index + 1).toString();
  const nextUrl = `/blog/${nextID}/`;

  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main backpage">
                  <div className="intro-wrap intro-wrap-accent-1">
                    <h1 className="intro-section intro-section-listing-h2">{`All Blogs / Page ${index}`}</h1>
                  </div>

                  <div className="search-quick-blog-top">
                    <a href="/search/">
                      Search blogs by keyword{" "}
                      <i className="fa-solid fa-search" />
                    </a>
                  </div>

                  {/* -- */}
                  <div>
                    <BlogLists data={group} layoutType={`grid-top`} />
                  </div>

                  <div className="paginator-blog">
                    {!first ? (
                      <div className="linking-the-pages previous">
                        <a href={`${prevUrl}`} className="">
                          <span>
                            <i className="fa-regular fa-circle-left"></i>
                          </span>
                          Go to Previous Page
                        </a>
                      </div>
                    ) : (
                      false
                    )}
                    {!last ? (
                      <div className="linking-the-pages next">
                        <a href={nextUrl} className="">
                          Go to Next Page
                          <span>
                            <i className="fa-regular fa-circle-right"></i>
                          </span>
                        </a>
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
                {/* - - -  */}
                <DynamicSidebar onlyNews="true" />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default BlogList;
